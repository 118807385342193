/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Badge, Card, Container, Grid, Typography,
} from '@mui/material';
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo';
import Layout, { LayoutContext } from '../components/Layout';
import howItWorks from '../img/how-it-works.png';
import arrowRight from '../img/arrow-right.svg';
import arrowDown from '../img/arrow-down.png';
import arrowLeft from '../img/arrow-left.svg';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
// eslint-disable-next-line

export function AboutPageTemplate({ title, steps, features }) {
  const { mobileMode } = React.useContext(LayoutContext);
  return (
    <>
      <Container
        maxWidth="none"
        sx={{ backgroundImage: `url(${howItWorks})`, backgroundSize: 'cover', height: 200 }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          sx={{ height: '100%' }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: 'Poppins',
              fontSize: '36px',
              fontWeight: '600',
              lineHeight: '54px',
              letterSpacing: '0.045em',
              textAlign: 'left',
              color: 'white',
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Container>
      <Card
        sx={{
          m: mobileMode ? 2 : 3,
          pt: mobileMode ? 6 : 12,
          pb: 4,
          px: mobileMode ? 1 : 8,
          backgroundColor: 'rgba(90, 184, 98, 0.11)',
          borderRadius: '20px',
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="column"
            sx={{
              flexWrap: 'nowrap',
            }}
            alignItems="center"
            rowSpacing={mobileMode && 2}
          >
            <Grid item>
              <Grid
                container
                direction={mobileMode ? 'column' : 'row'}
                alignItems={mobileMode ? 'center' : 'flex-end'}
              >
                <Grid item xs="12" md="9" sx={!mobileMode && { p: 5, pr: 0 }}>
                  <Badge
                    sx={{ width: '100%' }}
                    anchorOrigin={
                      mobileMode
                        ? {
                          vertical: 'top',
                          horizontal: 'center',
                        }
                        : {
                          vertical: 'top',
                          horizontal: 'left',
                        }
                    }
                    badgeContent={(
                      <Typography
                        variant="h3"
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          fontFamily: 'Poppins',
                          fontSize: '34px',
                          fontWeight: '600',
                          lineHeight: '64px',
                          letterSpacing: '0em',
                          borderRadius: '13px',
                          padding: '0px 25px',
                        }}
                      >
                        Step 1
                      </Typography>
                    )}
                  >
                    <Card
                      sx={{
                        borderRadius: '16px',
                        p: 4,
                        pt: mobileMode ? 6 : 4,
                        width: '100%',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        direction={mobileMode ? 'column' : 'row'}
                        rowGap={2}
                      >
                        <Grid item xs="12" sm="7">
                          <Grid container direction="column" rowGap={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '38px',
                                fontWeight: '600',
                                lineHeight: '64px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                              }}
                            >
                              {steps.blurbs[0].text}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '22px',
                                fontWeight: '400',
                                lineHeight: '48px',
                                letterSpacing: '0px',
                                textAlign: 'justified',
                                color: '#000F2080',
                              }}
                            >
                              {steps.blurbs[0].subtitle}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs="12" sm="4">
                          <div style={{ height: '248', width: '237' }}>
                            <PreviewCompatibleImage
                              imageInfo={steps.blurbs[0]}
                              alt="Create Golf Group"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </Badge>
                </Grid>
                <Grid item xs={12} md="3">
                  <img
                    alt="Arrow UI Element that leads to Step 2"
                    src={mobileMode ? arrowDown : arrowRight}
                    style={
                      mobileMode
                        ? {
                          height: '150px',
                          marginTop: '-10px',
                          zIndex: '50000',
                          position: 'relative',
                        }
                        : {
                          height: '200px',
                          marginLeft: '-10px',
                          zIndex: '50000',
                          position: 'relative',
                        }
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction={mobileMode ? 'column-reverse' : 'row'}
                alignItems={mobileMode ? 'center' : 'flex-end'}
              >
                <Grid item xs="2.75" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <img
                    alt="Arrow UI Element that leads to Step 3"
                    src={mobileMode ? arrowDown : arrowLeft}
                    style={
                      mobileMode
                        ? {
                          height: '150px',
                          marginTop: '-10px',
                          zIndex: 50000,
                          position: 'relative',
                        }
                        : {
                          height: '215px',
                          marginRight: '-10px',
                          zIndex: 50000,
                          position: 'relative',
                        }
                    }
                  />
                </Grid>
                <Grid item xs="12" sm="9.25" sx={!mobileMode && { p: 5, pl: 0 }}>
                  <Badge
                    sx={{ width: '100%' }}
                    anchorOrigin={
                      mobileMode
                        ? { vertical: 'top', horizontal: 'center' }
                        : {
                          vertical: 'top',
                          horizontal: 'right',
                        }
                    }
                    badgeContent={(
                      <Typography
                        variant="h3"
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          fontFamily: 'Poppins',
                          fontSize: '34px',
                          fontWeight: '600',
                          lineHeight: '64px',
                          letterSpacing: '0em',
                          borderRadius: '13px',
                          padding: '0px 25px',
                        }}
                      >
                        Step 2
                      </Typography>
                    )}
                  >
                    <Card
                      sx={{
                        borderRadius: '16px',
                        p: 4,
                        pt: mobileMode ? 6 : 4,
                        width: '100%',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        direction={mobileMode ? 'column' : 'row'}
                        rowGap={2}
                      >
                        <Grid item xs="12" sm="7">
                          <Grid container direction="column" rowGap={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '38px',
                                fontWeight: '600',
                                lineHeight: '64px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                              }}
                            >
                              {steps.blurbs[1].text}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '22px',
                                fontWeight: '400',
                                lineHeight: '48px',
                                letterSpacing: '0px',
                                textAlign: 'justified',
                                color: '#000F2080',
                              }}
                            >
                              {steps.blurbs[1].subtitle}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs="12" sm="4">
                          <div style={{ height: '248', width: '237' }}>
                            <PreviewCompatibleImage
                              imageInfo={steps.blurbs[1]}
                              alt="Add friends to golf group to create foursome for golf"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </Badge>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction={mobileMode ? 'column' : 'row'}
                alignItems={mobileMode ? 'center' : 'flex-end'}
              >
                <Grid item xs="12" md="9" sx={!mobileMode && { p: 5, pr: 0 }}>
                  <Badge
                    sx={{ width: '100%' }}
                    anchorOrigin={
                      mobileMode
                        ? {
                          vertical: 'top',
                          horizontal: 'center',
                        }
                        : {
                          vertical: 'top',
                          horizontal: 'left',
                        }
                    }
                    badgeContent={(
                      <Typography
                        variant="h3"
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          fontFamily: 'Poppins',
                          fontSize: '34px',
                          fontWeight: '600',
                          lineHeight: '64px',
                          letterSpacing: '0em',
                          borderRadius: '13px',
                          padding: '0px 25px',
                        }}
                      >
                        Step 3
                      </Typography>
                    )}
                  >
                    <Card
                      sx={{
                        borderRadius: '16px',
                        p: 4,
                        pt: mobileMode ? 6 : 4,
                        width: '100%',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        direction={mobileMode ? 'column' : 'row'}
                        rowGap={2}
                      >
                        <Grid item xs="12" sm="7">
                          <Grid container direction="column" rowGap={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '38px',
                                fontWeight: '600',
                                lineHeight: '64px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                              }}
                            >
                              {steps.blurbs[2].text}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '22px',
                                fontWeight: '400',
                                lineHeight: '48px',
                                letterSpacing: '0px',
                                textAlign: 'justified',
                                color: '#000F2080',
                              }}
                            >
                              {steps.blurbs[2].subtitle}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs="12" sm="4">
                          <div style={{ height: '248', width: '237' }}>
                            <PreviewCompatibleImage
                              imageInfo={steps.blurbs[2]}
                              alt="View availabilities to create foursomes for golf"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </Badge>
                </Grid>
                <Grid item xs={12} md="3">
                  <img
                    alt="Arrow UI Element that leads to Step 4"
                    src={mobileMode ? arrowDown : arrowRight}
                    style={
                      mobileMode
                        ? {
                          height: '150px',
                          marginTop: '-10px',
                          zIndex: '50000',
                          position: 'relative',
                        }
                        : {
                          height: '200px',
                          marginLeft: '-10px',
                          zIndex: '50000',
                          position: 'relative',
                        }
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction={mobileMode ? 'column-reverse' : 'row'}
                alignItems={mobileMode ? 'center' : 'flex-end'}
              >
                <Grid item xs="2.75" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <img
                    alt="Arrow UI Element that leads to Step 5"
                    src={mobileMode ? arrowDown : arrowLeft}
                    style={
                      mobileMode
                        ? {
                          height: '150px',
                          marginTop: '-10px',
                          zIndex: 50000,
                          position: 'relative',
                        }
                        : {
                          height: '215px',
                          marginRight: '-10px',
                          zIndex: 50000,
                          position: 'relative',
                        }
                    }
                  />
                </Grid>
                <Grid item xs="12" sm="9.25" sx={!mobileMode && { p: 5, pl: 0 }}>
                  <Badge
                    sx={{ width: '100%' }}
                    anchorOrigin={
                      mobileMode
                        ? { vertical: 'top', horizontal: 'center' }
                        : {
                          vertical: 'top',
                          horizontal: 'right',
                        }
                    }
                    badgeContent={(
                      <Typography
                        variant="h3"
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          fontFamily: 'Poppins',
                          fontSize: '34px',
                          fontWeight: '600',
                          lineHeight: '64px',
                          letterSpacing: '0em',
                          borderRadius: '13px',
                          padding: '0px 25px',
                        }}
                      >
                        Step 4
                      </Typography>
                    )}
                  >
                    <Card
                      sx={{
                        borderRadius: '16px',
                        p: 4,
                        pt: mobileMode ? 6 : 4,
                        width: '100%',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        direction={mobileMode ? 'column' : 'row'}
                        rowGap={2}
                      >
                        <Grid item xs="12" sm="7">
                          <Grid container direction="column" rowGap={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '38px',
                                fontWeight: '600',
                                lineHeight: '64px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                              }}
                            >
                              {steps.blurbs[3].text}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '22px',
                                fontWeight: '400',
                                lineHeight: '48px',
                                letterSpacing: '0px',
                                textAlign: 'justified',
                                color: '#000F2080',
                              }}
                            >
                              {steps.blurbs[3].subtitle}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs="12" sm="4">
                          <div style={{ height: '248', width: '237' }}>
                            <PreviewCompatibleImage
                              imageInfo={steps.blurbs[3]}
                              alt="Create golf foursome"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </Badge>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction={mobileMode ? 'column' : 'row'}
                alignItems={mobileMode ? 'center' : 'flex-end'}
              >
                <Grid item xs="12" md="9" sx={!mobileMode && { p: 5, pr: 0 }}>
                  <Badge
                    sx={{ width: '100%' }}
                    anchorOrigin={
                      mobileMode
                        ? {
                          vertical: 'top',
                          horizontal: 'center',
                        }
                        : {
                          vertical: 'top',
                          horizontal: 'left',
                        }
                    }
                    badgeContent={(
                      <Typography
                        variant="h3"
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          fontFamily: 'Poppins',
                          fontSize: '34px',
                          fontWeight: '600',
                          lineHeight: '64px',
                          letterSpacing: '0em',
                          borderRadius: '13px',
                          padding: '0px 25px',
                        }}
                      >
                        Step 5
                      </Typography>
                    )}
                  >
                    <Card
                      sx={{
                        borderRadius: '16px',
                        p: 4,
                        pt: mobileMode ? 6 : 4,
                        width: '100%',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        direction={mobileMode ? 'column' : 'row'}
                        rowGap={2}
                      >
                        <Grid item xs="12" sm="7">
                          <Grid container direction="column" rowGap={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '38px',
                                fontWeight: '600',
                                lineHeight: '64px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                              }}
                            >
                              {steps.blurbs[4].text}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '22px',
                                fontWeight: '400',
                                lineHeight: '48px',
                                letterSpacing: '0px',
                                textAlign: 'justified',
                                color: '#000F2080',
                              }}
                            >
                              {steps.blurbs[4].subtitle}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs="12" sm="4">
                          <div style={{ height: '248', width: '237' }}>
                            <PreviewCompatibleImage
                              imageInfo={steps.blurbs[4]}
                              alt="Find tee times near you"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </Badge>
                </Grid>
                <Grid item xs={12} md="3" />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Card>
      <Container maxWidth="none" sx={{ backgroundColor: '#5AB862' }} id="features">
        <Container maxWidth="lg">
          <Grid container direction="column" alignItems="center" sx={{ py: 8 }} rowGap={8}>
            <Typography
              variant="h3"
              sx={{
                color: 'white',
                fontFamily: 'Poppins',
                fontSize: '38px',
                fontWeight: '600',
                lineHeight: '64px',
                letterSpacing: '0em',
                textAlign: mobileMode ? 'center' : 'left',
              }}
            >
              Additional Features
            </Typography>
            <Grid container justifyContent="center" spacing={10}>
              <Grid item xs="12" md="5.5">
                <Card
                  sx={{
                    p: 4,
                    borderRadius: '18px',
                    textAlign: 'center',
                    minHeight: '500px',
                  }}
                >
                  <Grid container direction="column" rowGap={2} alignItems="center">
                    <div style={{ height: '132px', width: '132px' }}>
                      <PreviewCompatibleImage
                        imageInfo={features.blurbs[0]}
                        alt="Join public golf groups"
                      />
                    </div>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '33px',
                        fontWeight: '600',
                        lineHeight: '64px',
                        letterSpacing: '0em',
                      }}
                    >
                      {features.blurbs[0].text}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '22px',
                        fontWeight: '400',
                        lineHeight: '36px',
                        letterSpacing: '0px',
                        color: '#000F2080',
                      }}
                    >
                      {features.blurbs[0].subtitle}
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs="12" md="5.5">
                <Card
                  sx={{
                    p: 4,
                    borderRadius: '18px',
                    textAlign: 'center',
                    minHeight: '500px',
                  }}
                >
                  <Grid container direction="column" rowGap={2} alignItems="center">
                    <div style={{ height: '132px', width: '132px' }}>
                      <PreviewCompatibleImage
                        imageInfo={features.blurbs[1]}
                        alt="Create foursome for golf flash game"
                      />
                    </div>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '33px',
                        fontWeight: '600',
                        lineHeight: '64px',
                        letterSpacing: '0em',
                      }}
                    >
                      {features.blurbs[1].text}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '22px',
                        fontWeight: '400',
                        lineHeight: '36px',
                        letterSpacing: '0px',
                        color: '#000F2080',
                      }}
                    >
                      {features.blurbs[1].subtitle}
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  features: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

function AboutPage({ data }) {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <GatsbySeo
        title="How It Works | Squaddie - The Caddie for your Golf Squad"
        description="Learn how to easily schedule your next golf foursome with Squaddie. The foursome scheduler app. Follow these simple steps to find coordinate availability with your golf group and plan more foursomes for golf. With our user-friendly interface and powerful features, you can enjoy more time on the greens and less time on logistics. Try it now and experience the ultimate golf scheduling solution."
        canonical="https://www.squaddie.co/how-to-create-golf-foursome"
      />
      <JsonLd
        json={{
          url: 'https://www.squaddie.co/how-to-create-golf-foursome',
          // images: [
          //   frontmatter.steps.blurbs.map((x) => x.image),
          //   frontmatter.features.blurbs.map((x) => x.image),
          // ],
          datePublished: '2023-03-10T08:00:00+08:00',
          // dateModified="2023-03-10T09:00:00+08:00"
          description:
            'Learn how to easily schedule your next golf foursome with Squaddie. The foursome scheduler app. Follow these simple steps to find coordinate availability with your golf group and plan more foursomes for golf. With our user-friendly interface and powerful features, you can enjoy more time on the greens and less time on logistics. Try it now and experience the ultimate golf scheduling solution.',
          '@context': 'https://schema.org',
          '@type': 'HowTo',
          name: 'How to use the Squaddie app',
        }}
      />

      <AboutPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        steps={frontmatter.steps}
        features={frontmatter.features}
      />
    </Layout>
  );
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        steps {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 100, layout: CONSTRAINED)
              }
            }
            text
            subtitle
          }
        }
        features {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 100, layout: CONSTRAINED)
              }
            }
            text
            subtitle
          }
        }
      }
    }
  }
`;
